@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}


.warningMessage{
  font-size: 14px;
  color: red;
  margin: 50px 0px 20px;
}


.ageDetailsTitle{
  margin-top: 20px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}